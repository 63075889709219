
.figure {
	display: inline-block;
	fill: #a6b2c4;
	&.selected {
		fill:#002257;
	}
	&.hover {
		fill:#0057dd;
	}
}

.icons--bg-01 {
	width: 399px;
	height: 440px;
}
.icons--bg-02 {
	width: 400px;
	height: 440px;
}
.icons--bg-03 {
	width: 400px;
	height: 440px;
}
.icons--center {
	width: 80px;
	height: 80px;
}
.icons--circles {
	width: 563px;
	height: 369px;
}
.icons--circles-thin {
	width: 681px;
	height: 681px;
}
.icons--cooperation {
	width: 80px;
	height: 80px;
}
.icons--gov {
	width: 80px;
	height: 80px;
}
.icons--hand {
	width: 463px;
	height: 207px;
}
.icons--top {
	width: 1032px;
	height: 747px;
}
