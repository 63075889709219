/**
 * Основные стили
 **/
*{
  text-decoration: none !important;
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
  background: #fff;
  height: 100%;
}

body {
  font-family: Exo2;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #212b36;
}

button{
  background: transparent;
  border: none;

  &:focus{
    outline: none;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  margin-bottom: 0.5rem;
}

h1{
  font-family: SourceCodePro;
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #a83c92;
}

h2{
  font-family: SourceCodePro;
  font-size: 35px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.37;
  letter-spacing: normal;
  color: #3b059b;
}

h3{
  font-family: Exo2;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #000000;
}

h3.type-2{
  font-family: Exo2;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #000000;
}

h3.type-3{
  font-family: SourceCodePro;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: center;
}



.wrapper{
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding-top: 63px;

  > .container{

    > .content{
      section{
        min-height: 200px;
        padding: 72px 100px 0;
        position: relative;
        overflow: hidden;

        > *{
          z-index: 1;
          position: relative;
        }

        &.type-1{
          h1{
            max-width: 552px;
          }

          h2{
            margin-top: 350px;
            text-align: center;
            z-index: 2;
          }

          h3{
            &.description{
              max-width: 458px;
              margin-top: 10px;
              width: 40%;
            }
          }

          .container{
            display: flex;
            justify-content: space-between;
            margin-top: 23px;

            .quote{
              max-width: 458px;
              width: 40%;
              flex-shrink: 0;
              margin-right: 40px;
              position: relative;

              .bg{
                position: absolute;
                z-index: -1;
                left: -15px;
                top: -145px;

                &:after{
                  content: "“";
                  font-family: SourceCodePro;
                  font-size: 300px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #f9f9fd;
                }
              }
            }

            .info{
              padding-top: 8px;
            }
          }

          .btn-center-row{
            margin-top: 58px;
          }

          .bg{
            position: absolute;
            top: -30px;
            right: 35px;
            width: 100%;
            max-width: 1032px;
            z-index: 0;

            svg{
              width: 100%;
            }
          }
        }

        &.type-2{
           h2{
             margin-top: 0px;
             text-align: center;
             z-index: 2;
           }

           .container{
             display: flex;
             justify-content: center;
             margin-top: 23px;
             position: relative;

             .items-list{
               display: flex;
               flex-direction: column;
               max-width: 830px;
               position: relative;

               .bg{
                 position: absolute;
                 top: 100px;
                 left: 0px;
                 width: 100%;
                 max-width: 681px;
                 z-index: -1;

                 svg{
                   width: 100%;
                 }
               }

               .item{
                 display: flex;
                 justify-content: space-between;
                 margin-top: 100px;
                 align-items: center;

                 .ico{
                   width: 140px;
                   height: 140px;
                   border-radius: 70px;
                   box-shadow: 0 0px 5px 20px rgba(116, 124, 249, 0.01);
                   background-color: #ffffff;
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   margin-right: 100px;
                   flex-shrink: 0;
                   position: relative;

                   &:after{
                     content: "";
                     width: 180px;
                     height: 180px;
                     position: absolute;
                     top: -20px;
                     left: -20px;
                     background: linear-gradient(to top, #F0F0FE, #F9F9FF);
                     border-radius: 90px;
                     z-index: -1;
                   }
                 }

                 h3{

                 }
               }
             }

           }

           .btn-center-row{
             margin-top: 58px;
           }


         }

        &.type-3{
          background-image: linear-gradient(to top, #F0F2FF, #fff);
          padding-bottom: 82px;

          h2{
            margin-top: 0px;
            text-align: center;
            z-index: 2;
          }

          .container{
            display: flex;
            justify-content: center;
            margin-top: 23px;
            position: relative;

            .items-list{
              display: flex;
              flex-direction: row;
              position: relative;
              justify-content: space-between;

              .item{
                display: flex;
                justify-content: flex-start;
                margin-top: 100px;
                align-items: center;
                flex-direction: column;
                position: relative;
                max-width: 360px;
                min-height: 400px;
                margin-right: 40px;
                background: #fff;
                padding: 0 30px 30px;
                flex-shrink: 0;
                width: calc(33% - 27px);
                box-shadow: 0 5px 20px 0 rgba(130, 136, 255, 0.16);

                &:last-child{
                  margin-right: 0;
                }

                .bg{
                  position: absolute;
                  top:0;
                  left: 0;
                  z-index: 0;
                  width: 100%;
                  height: 100%;
                  overflow: hidden;

                  svg{
                    width: 110%;
                    height: 110%;
                  }
                }

                .ico{
                  width: 140px;
                  height: 140px;
                  border-radius: 70px;
                  box-shadow: 0 0px 5px 20px rgba(116, 124, 249, 0.1);
                  background-color: #ffffff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 100px;
                  flex-shrink: 0;
                }

                h3{
                  z-index: 1;
                  position: relative;
                  margin-top: 60px;
                }

                .info{
                  z-index: 1;
                  position: relative;
                  margin-top: 7px;
                  text-align: center;
                }
              }
            }

          }

          .btn-center-row{
            margin-top: 58px;
          }


        }

        &.type-4{
           padding-bottom: 80px;
           padding-left: 0;

           .container{
             display: flex;
             justify-content: space-between;

             .left-part{
               margin-right: 20px;
               position: relative;
               max-width: 463px;
               width: 40%;
               flex-shrink: 0;

               .bg{
                 position: absolute;
                 left: -10px;
                 top: -20px;
                 width: 100%;
                 display: flex;
                 align-items: flex-start;

                 svg{
                   width: 100%;

                 }
               }
             }

             .right-part{
               flex-shrink: 0;
               width: 60%;

               h3{
                 margin-top: 32px;
               }

               .info{
                 margin-top: 32px;
               }
             }
           }
         }

        &.type-5{
          padding-bottom: 80px;
          padding-left: 0;

          .bg {
            position: fixed;
            top: -20px;
            left: calc(50% - 915px);
            width: 100%;
            max-width: 1032px;
            z-index: 0;

            svg {
              width: 100%;
            }
          }

          section{
            padding: 0;
          }

          .container{
            display: flex;
            justify-content: space-between;

            .left-part{
              margin-right: 20px;
              position: relative;
              width: 360px;
              flex-shrink: 0;
              position: relative;

              .left-side{
                position: fixed;
                top: 151px;
                left: calc(50% - 589px);
                width: 360px;
                background: #fff;
                box-shadow: 0 5px 20px 0 rgba(130, 136, 255, 0.16);

                ul{
                  li{


                    a{
                      padding: 25px 36px;
                      border-left: 4px solid transparent;
                      display: block;

                      &:hover{
                        color: #000;
                      }

                      &[class *= "-highlight"]{
                        border-left: 4px solid #a83c92;
                          color: #000;
                      }
                    }




                    a{
                      font-family: Exo2;
                      font-size: 20px;
                      font-weight: normal;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: 1.6;
                      letter-spacing: normal;
                      color: #3b059b;
                    }
                  }
                }
              }
            }

            .right-part{
              flex-shrink: 0;
              width: calc(100% - 495px);

              &.more-1024{
                display: block;
              }

              &.less-1024{
                display: none;
              }

              h3{
                margin-top: 32px;
              }

              .info{
                margin-top: 32px;
              }
            }
          }
        }

        &.type-6{
          padding-bottom: 80px;
          padding-left: 0;

          .container{
            display: flex;
            justify-content: space-between;

            .left-part{
              margin-right: 20px;
              position: relative;
              max-width: 463px;
              width: 40%;
              flex-shrink: 0;

              .bg{
                position: absolute;
                left: -10px;
                top: -20px;
                width: 100%;
                display: flex;
                align-items: flex-start;

                svg{
                  width: 100%;

                }
              }
            }

            .right-part{
              flex-shrink: 0;
              width: 60%;

              h3{
                margin-top: 32px;
              }

              .info{
                margin-top: 32px;

                >.item{
                  display: flex;

                  div{
                    font-family: Exo2;
                    font-size: 20px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 2;
                    letter-spacing: normal;
                    color: #3b059b;

                    &:first-child{
                      color: #212b36;
                    }

                    &:last-child{
                      margin-left: 6px;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }
  }
}

nav.navbar{
  display: flex;
  height: 63px;
  justify-content: center;
  border-bottom: 1px solid #eeeff5;
  padding: 0 100px;
  max-width: 1366px;
  width: 100%;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 11;
  flex-shrink: 0;

  .container{
    width: 100%;
    display: flex;

    > .toggle-search{
      display: none;
    }

    > .toggle-menu{
      width: 20px;
      height: 14px;
      border-top: 2px solid #c6c7d8;
      border-bottom: 2px solid #c6c7d8;
      border-left: none;
      border-right: none;
      position: relative;
      background: none;
      border-radius: 1px;
      margin-top: 22px;
      display: none;

      &:after{
        content: "";
        width: 100%;
        height: 2px;
        background: #c6c7d8;
        top: 4px;
        position: absolute;
        left: 0;
      }

      &:hover{
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;

        &:after{
          background: #000;
        }
      }
    }

    .navbar-brand.logo{
      margin-top: 8px;
      margin-right: 50px;
      background: url(/img/images/logo.png) no-repeat;
      width: 62px;
      height: 70px;
      background-size: contain;
      flex-shrink: 0;

      .icons--placeholder{
        fill: #3b059b;
        display: none;
      }
    }

    .wrap-links{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      > .toggle-menu{
        display: none;
      }

      .navbar-nav{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        align-items: center;

        .nav-item{

          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 2px solid transparent;

          &.lan-switcher-1{
            display: flex;
          }
          &.lan-switcher-2{
            display: none;
          }

          .nav-link{
            font-family: 'Exo2';
            font-size: 15px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #73758b;
            line-height: 18px;
          }

          &.active{

            border-bottom: 2px solid #3b059b;

            a{
              color: #000;
            }
          }

          &:hover{
            a{
              color: #000;
            }
          }

          &.no-hover{
            border-bottom: 2px solid transparent !important;
          }
        }
      }
    }
  }
}

footer{
  height: 167px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 22px 100px 21px;
  flex-shrink: 0;
  background-color: #fff;

  .footer-top{
    display: flex;

    > .container{
      display: flex;
      justify-content: space-between;
      width: 100%;

      .footer-logo{
        margin-right: 140px;
        background: url(/img/images/logo.png) no-repeat;
        width: 62px;
        height: 70px;
        background-size: contain;
        flex-shrink: 0;

        svg{
          display: none;
        }
      }

      .menu-columns{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-right: 185px;

          .col{
            li{
              margin-top: 7px;
              height: 22px;
              a{
                font-family: 'Exo2';
                font-size: 15px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #73758b;
                line-height: 18px;
              }

              &:first-child{
                margin-top: 0;
              }
            }
          }
      }
    }
  }

  .copyright-area{
    .container{
      display: flex;
      justify-content: center;
      height: 30px;
      align-items: center;

      .center-content-area{
        font-family: Exo2;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #212b36;
        line-height: 17px;
      }
    }
  }

}

a.button{
  &.more{
    height: 52px;
    width: 162px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3b059b;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(75, 83, 255, 0.3);

    &:hover{
      background-color: #2a0768;
    }
  }
}

.btn-center-row{
  display: flex;
  justify-content: center;
}

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(3, 5, 31, 0.94);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;

  &.search--open {
    pointer-events: auto;
    opacity: 1;
  }

  .search__form{
    max-width: 800px;

  .search__input {
    border: 0;
    font-family: inherit;
    font-size: 40px;
    line-height: 1;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 0.05em 0;
    color: #fff;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    border-radius: 0;
    -webkit-appearance: none;
    font-family: SourceCodePro;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;

    &::placeholder{
      color: rgba(255, 255, 255, 0.3);
    }

    &::-webkit-search-cancel-button{
      -webkit-appearance: none;
    }
  }

  .search__info {
    font-family: Exo2;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    text-align: right;
    color: rgba(255, 255, 255, 0.3);
  }
  }


  button#btn-search-close {
    position: absolute;
    top: 20px;
    right: calc(50% - 340px);
    background: none;
    border: none;

    svg{
      fill:#fff;
    }

    &:hover{
      cursor: pointer;

      svg{
        fill: #000;
      }
    }
  }
}

select{
  display: none;
}

.chosen-container-single .chosen-single{
  height: 38px;
  line-height: 34px;
  background: none;
  border: none;
  box-shadow: 0 1px 4px 0 rgba(38, 40, 42, 0.1);
  font-family: Exo2;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #73758b;
  text-transform: uppercase;
  line-height: 38px;
  padding-left: 10px;

  &:hover{
    color: #73758b !important;
  }
}

.chosen-container .chosen-drop {
  border: none;
  box-shadow: none;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-image: none;
  background-image: none;
  box-shadow: 0 1px 4px 0 rgba(38, 40, 42, 0.1);
  font-family: Exo2;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #73758b;
  text-transform: uppercase;
  line-height: 38px;
}

.chosen-container .chosen-results {
  margin:0;
  padding: 0;

}

.chosen-container .chosen-results li {
  font-family: Exo2;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #73758b;
  text-transform: uppercase;
  line-height: 38px;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
}

.chosen-container .chosen-results li:hover, .chosen-container .chosen-results li.highlighted{
  background: transparent;
  color: #000;
}

.chosen-container.chosen-with-drop .chosen-drop {
  box-shadow: 0 1px 4px 0 rgba(38, 40, 42, 0.1);
}

.chosen-container-single .chosen-single div {
  position: absolute;
  top: 0;
  right: 12px;
  display: block;
  width: 11px;
  height: 100%;
}

.chosen-container-single .chosen-single div b{
  background-position: 0 9px;
}

.chosen-container-active.chosen-with-drop .chosen-single div b{
  background-position: -18px 9px;
}

.switchery{
  height: 18px;
  width: 33px;

  small{
    height: 18px;
    width: 18px;
  }
}

.icons--close, .icons--search{
  &:hover{
    fill:#000;
  }
}

.no-scroll{
  overflow: hidden;
}










@media screen and (max-width: 1366px) {

  .wrap-links{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  nav.navbar{
    .container {
      justify-content: space-between;

      > .toggle-menu{
        display: block;
      }

      > .toggle-search{
        display: block;
      }

      .navbar-brand.logo{
        margin-right: 0;
        margin-left: 16px;

        svg{
          display: none;
        }
      }


      .wrap-links {
        visibility: hidden;
        background-color: #fff;
        padding: 20px 40px;
        flex-direction: column;
        align-items: flex-start;

        .toggle-menu{
          display: block;
          margin-bottom: 40px;
        }

        .navbar-nav{
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .nav-item{
            height: 32px;
            margin-bottom: 27px;

            &.lan-switcher-1{
              display: none;
            }
            &.lan-switcher-2{
              display: block;

              span{
                font-family: Exo2;
                font-size: 15px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #73758b;

                &.lan-ua{
                  margin-right: 12px;
                }

                &.lan-eng{
                  margin-left: 12px;
                }
              }

              input{
                margin: 0 12px;
              }

            }

            &.toggle-search{
              display: none;
            }

          }

          .nav-border {
            width: calc(100% + 80px);
            height: 1px;
            background: #eee;
            position: relative;
            left: -40px;
            margin-bottom: 40px;
          }
        }
      }
    }
  }

  .toggled{
    nav.navbar{
      .container {
        .wrap-links {
          visibility: visible;

          .navbar-nav{

            .nav-item{

            }
          }
        }
      }
    }
  }


  .wrapper {
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding-top: 63px;

    > .container {

      > .content {
        section {
          &.type-5 {
            padding-bottom: 80px;
            padding-left: 0;

            section {
              padding: 0;
            }

            .container {
              display: flex;
              justify-content: space-between;

              .left-part {
                margin-right: 20px;
                position: relative;
                width: 360px;
                flex-shrink: 0;
                position: relative;

                .left-side {
                  position: fixed;
                  top: 151px;
                  left: 100px;
                  width: 360px;
                  background: #fff;
                  box-shadow: 0 5px 20px 0 rgba(130, 136, 255, 0.16);

                }
              }

              .right-part {
                flex-shrink: 0;
                width: calc(100% - 495px);

                h3 {
                  margin-top: 32px;
                }

                .info {
                  margin-top: 32px;
                }
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 1200px) {
  footer .footer-top > .container .menu-columns{
    margin-right: 0px;
  }
}

@media screen and (max-width: 991px) {
  .wrapper{

    > .container{
      > .content{
        section{
          &.type-1{
            h1{

            }

            h2{

            }

            h3{
              &.description{

              }
            }

            .bg{
              top: -30px;
              right: -2%;
              width: 105%;
            }
          }

          &.type-5 {
            padding-bottom: 80px;
            padding-left: 100px;

            .bg {
              position: fixed;
              top: 80px;
              left: 30%;
              width: 80%;
              max-width: 1032px;
              z-index: 0;
            }

            section {
              padding: 0;
            }

            .container {
              display: flex;
              justify-content: space-between;
              flex-direction: column;

              .left-part {
                margin-right: 20px;
                position: relative;
                width: 100%;
                flex-shrink: 0;
                position: relative;
                display: none;

                .left-side {
                  position: fixed;
                  top: 103px;
                  left: 100px;
                  width: calc(100% - 200px);
                  background: #fff;
                  box-shadow: 0 5px 20px 0 rgba(130, 136, 255, 0.16);

                  ul {
                    display: flex;
                    li {
                      padding: 25px 36px;
                      border-bottom: 4px solid transparent;
                      border-left: none;

                      &:hover {

                        a {
                          color: #000;
                        }
                      }

                      &.active {
                        border-bottom: 4px solid #a83c92;
                        border-left: none;
                        a{
                          color: #000;
                        }
                      }

                      a {
                        font-family: Exo2;
                        font-size: 20px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.6;
                        letter-spacing: normal;
                        color: #3b059b;
                      }
                    }
                  }
                }
              }

              .right-part {
                flex-shrink: 0;
                width: 100%;

                &.more-1024{
                  display: none;
                }

                &.less-1024{
                  display: block;
                  margin-left: -20px;
                  margin-right: -20px;
                  width: calc(100% + 40px);

                  .accordion-group{
                    padding: 0;

                    > section{
                      min-height: auto;
                      margin: 0;
                      box-shadow: 0 1px 4px 0 rgba(38, 40, 42, 0.1);
                      margin-top: 16px;
                      background-color: #fff;

                      header{
                        h3{
                          margin-top: 0px;

                          button{
                            background-color: #fff;

                            border: none;
                            border-radius: 0;
                            display: block;
                            font-family: Exo2;
                            font-size: 18px;
                            font-weight: 500;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 1.56;
                            letter-spacing: normal;
                            color: #3b059b;
                            width: 100%;
                            padding: 12px 63px 12px 16px;
                            position: relative;

                            &.accordion-group__accordion-btn_expanded{
                              &:after{
                                content: "";
                                position: absolute;
                                right: 20px;
                                top: calc(50% - 5px);
                                width: 10px;
                                height: 10px;
                                background: url(/img/images/chosen-sprite.png) no-repeat -19px -4px;
                              }
                            }

                            &:after{
                              content: "";
                              position: absolute;
                              right: 20px;
                              top: calc(50% - 5px);
                              width: 10px;
                              height: 10px;
                              background: url(/img/images/chosen-sprite.png) no-repeat -1px -4px;
                            }
                          }
                        }
                      }

                      .accordion-group__accordion-panel{
                        border: none;
                        border-radius: 0;
                        padding: 0.5rem 1rem;
                      }
                    }
                  }
                }

                h3 {
                  margin-top: 32px;
                }

                .info {
                  margin-top: 32px;
                }
              }
            }
          }

        }
      }
    }
  }

  footer .footer-top > .container .footer-logo {
    margin-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 20px;
  }

  h1{
    font-family: SourceCodePro;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #a83c92;
  }

  h2{
    font-family: SourceCodePro;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #3b059b;
  }

  h3{
    font-family: Exo2;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000;
  }

  h3.type-2{
    font-family: Exo2;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000;
  }

  h3.type-3{
    font-family: SourceCodePro;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: center;
  }

  nav.navbar {
    padding: 0 40px;
  }

  .wrapper{
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding-top: 63px;

    > .container{

      > .content{
        section{
          min-height: 200px;
          padding: 40px 20px 0;
          position: relative;
          overflow: hidden;

          > *{
            z-index: 1;
            position: relative;
          }

          &.type-1{
            h1{
              max-width: none;
              text-align: center;
            }

            h2{
              margin-top: 250px;
              text-align: center;
              z-index: 2;
            }

            h3{
              text-align: center;
              &.description{
                max-width: none;
                margin-top: 20px;
                width: 100%;
                text-align: center;
              }
            }

            .container{
              display: flex;
              justify-content: space-between;
              margin-top: 23px;
              flex-direction: column;

              .quote{
                max-width: none;
                width: 100%;
                flex-shrink: 0;
                margin-right: 40px;
                position: relative;

                .bg{
                  position: absolute;
                  z-index: -1;
                  left: -15px;
                  top: -145px;

                  &:after{
                    content: "“";
                    font-family: SourceCodePro;
                    font-size: 300px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #f9f9fd;
                  }
                }
              }

              .info{
                padding-top: 8px;
                text-align: center;
              }
            }

            .btn-center-row{
              margin-top: 42px;
            }

            .bg{
              position: absolute;
              top: -30px;
              right: 10%;
              width: 100%;
              max-width: none;
              z-index: 0;

              svg{
                width: 120%;
              }
            }
          }

          &.type-2{
            padding-top: 80px;

            h2{
              margin-top: 0px;
              text-align: center;
              z-index: 2;
            }

            .container{
              display: flex;
              justify-content: center;
              margin-top: 36px;
              position: relative;

              .items-list{
                display: flex;
                flex-direction: column;
                max-width: 830px;
                position: relative;

                .bg{
                  position: absolute;
                  top: 100px;
                  left: 0px;
                  width: 100%;
                  max-width: 581px;
                  z-index: -1;

                  svg{
                    width: 100%;
                  }
                }

                .item{
                  display: flex;
                  justify-content: space-between;
                  margin-top: 72px;
                  align-items: center;
                  flex-direction: column;

                  &.first-child{
                    margin-top: 0px;
                  }

                  .ico{
                    width: 140px;
                    height: 140px;
                    border-radius: 70px;
                    /*box-shadow: 0 0px 5px 20px rgba(116, 124, 249, 0.1);*/
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0px;
                    margin-bottom: 30px;
                    flex-shrink: 0;
                    position: relative;

                  }

                  h3{
                    text-align: center;
                  }
                }
              }

            }

            .btn-center-row{
              margin-top: 42px;
            }


          }

          &.type-3{
            background-image: linear-gradient(to top, #F0F2FF, #fff);
            padding-bottom: 82px;
            padding-top: 80px;

            h2{
              margin-top: 0px;
              text-align: center;
              z-index: 2;
            }

            .container{
              display: flex;
              justify-content: center;
              margin-top: 23px;
              position: relative;

              .items-list{
                display: flex;
                flex-direction: row;
                position: relative;
                justify-content: space-between;
                flex-direction: column;

                .item{
                  display: flex;
                  justify-content: flex-start;
                  margin-top: 30px;
                  align-items: center;
                  flex-direction: column;
                  position: relative;
                  max-width: none;
                  min-height: 280px;
                  margin-right: 0px;
                  background: #fff;
                  padding: 0 30px 30px;
                  flex-shrink: 0;
                  width: 100%;
                  box-shadow: 0 5px 20px 0 rgba(130, 136, 255, 0.16);

                  .last-child{
                    margin-top: 8px;
                  }

                  .bg{
                    position: absolute;
                    top:0;
                    left: 0;
                    z-index: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;

                    svg{
                      width: 770px;
                      height: 770px;
                      position: relative;
                      top: -370px;
                      opacity: 0.7;
                    }
                  }

                  .ico{
                    width: 140px;
                    height: 140px;
                    border-radius: 70px;
                  /*  box-shadow: 0 0px 5px 20px rgba(116, 124, 249, 0.1);*/
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 100px;
                    flex-shrink: 0;
                  }

                  h3{
                    z-index: 1;
                    position: relative;
                    margin-top: 40px;
                  }

                  .info{
                    z-index: 1;
                    position: relative;
                    margin-top: 7px;
                    text-align: center;
                  }
                }
              }

            }

            .btn-center-row{
              margin-top: 42px;
            }


          }

          &.type-4{
            padding-bottom: 80px;
            padding-left: 20px;

            .container{
              display: flex;
              justify-content: space-between;
              flex-direction: column;

              .left-part{
                margin-right: 20px;
                position: relative;
                max-width: 463px;
                width: 0%;
                flex-shrink: 0;

                .bg{
                  position: absolute;
                  left: -10px;
                  top: -20px;
                  width: 100%;
                  display: flex;
                  align-items: flex-start;

                  svg{
                    width: 100%;

                  }
                }
              }

              .right-part{
                flex-shrink: 0;
                width: 100%;

                h1{
                  text-align: center;
                }

                h3{
                  margin-top: 32px;
                  text-align: center;
                }

                .info{
                  margin-top: 32px;
                  text-align: center;
                }
              }
            }
          }

          &.type-5{
            padding-bottom: 80px;
            padding-left: 20px;

            section{
              padding: 0;
            }

            .container{
              display: flex;
              justify-content: space-between;

              .left-part{
                margin-right: 20px;
                position: relative;
                max-width: 463px;
                width: 40%;
                flex-shrink: 0;
                position: relative;

                .left-side{
                  position: fixed;
                  top: 241px;
                  left: 100px;
                  max-width: 360px;
                  width: 40%;
                  background: #fff;
                  box-shadow: 0 5px 20px 0 rgba(130, 136, 255, 0.16);

                  ul{
                    li{
                      padding: 25px 36px;
                      border-left: 4px solid transparent;

                      &:hover{
                        border-left: 4px solid #a83c92;

                        a{
                          color: #3b059b;
                        }
                      }

                      &.active{
                        border-left: 4px solid #a83c92;
                      }

                      a{
                        font-family: Exo2;
                        font-size: 20px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.6;
                        letter-spacing: normal;
                        color: #212b36;
                      }
                    }
                  }
                }
              }

              .right-part{
                flex-shrink: 0;
                width: 100%;

                &.more-768{
                  display: none;
                }

                &.more-768{
                  display: flex;
                }

                h1{
                  text-align: center;
                }

                h3{
                  margin-top: 32px;
                  text-align: center;
                }

                .info{
                  margin-top: 16px;
                  text-align: center;
                }
              }
            }
          }
          &.type-6{
            padding-bottom: 80px;
            padding-left: 20px;

            .container{
              display: flex;
              justify-content: space-between;

              .left-part{
                margin-right: 20px;
                position: relative;
                max-width: 463px;
                width: 40%;
                flex-shrink: 0;
                display: none;

                .bg{
                  position: absolute;
                  left: -10px;
                  top: -20px;
                  width: 100%;
                  display: flex;
                  align-items: flex-start;
                  display: none;

                  svg{
                    width: 100%;

                  }
                }
              }

              .right-part{
                flex-shrink: 0;
                width: 100%;

                h1{
                  text-align: center;
                }

                h3{
                  margin-top: 32px;
                  text-align: center;
                }

                .info{
                  margin-top: 32px;

                  >.item{
                    display: flex;
                    justify-content: center;

                    div{
                      font-family: Exo2;
                      font-size: 20px;
                      font-weight: normal;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: 2;
                      letter-spacing: normal;
                      color: #3b059b;

                      &:first-child{
                        color: #212b36;
                      }

                      &:last-child{
                        margin-left: 6px;
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
  }

  footer{
    height: 348px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 40px 14px;
    flex-shrink: 0;
    z-index: 10;

    .footer-top{
      display: flex;
      flex-direction: column;


      > .container{
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
        align-items: center;

        .footer-logo{
          margin-right: 0px;
          margin-top: -26px;
          margin-bottom: 5px;

        }

        .menu-columns{
          width: 100%;
          display: flex;
          justify-content: center;
          margin-right: 0px;
          flex-direction: column;

          .col{

            height: auto;
            li{
              margin-top: 6px;
              text-align: center;
              height: auto;
              a{
                font-family: 'Exo2';
                font-size: 15px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #73758b;
              }

              &:first-child{
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .copyright-area{
      .container{
        display: flex;
        justify-content: center;
        height: 30px;
        align-items: center;

        .center-content-area{
          font-family: Exo2;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #212b36;
          line-height: 17px;
          text-align: center;
        }
      }
    }

  }

  .search button#btn-search-close {
    right: 20px;
  }
}



