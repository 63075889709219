@font-face {
  font-family: 'SourceCodePro';
  src: url('/fonts/SourceCodePro/SourceCodePro-Medium.eot');
  src: url('/fonts/SourceCodePro/SourceCodePro-Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/SourceCodePro/SourceCodePro-Medium.woff2') format('woff2'),
  url('/fonts/SourceCodePro/SourceCodePro-Medium.woff') format('woff'),
  url('/fonts/SourceCodePro/SourceCodePro-Medium.ttf') format('truetype'),
  url('/fonts/SourceCodePro/SourceCodePro-Medium.svg#SourceCodePro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2';
  src: url('/fonts/Exo2/Exo2-Regular.eot');
  src: url('/fonts/Exo2/Exo2-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Exo2/Exo2-Regular.woff2') format('woff2'),
  url('/fonts/Exo2/Exo2-Regular.woff') format('woff'),
  url('/fonts/Exo2/Exo2-Regular.ttf') format('truetype'),
  url('/fonts/Exo2/Exo2-Regular.svg#Exo2-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2';
  src: url('/fonts/Exo2/Exo2-Medium.eot');
  src: url('/fonts/Exo2/Exo2-Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Exo2/Exo2-Medium.woff2') format('woff2'),
  url('/fonts/Exo2/Exo2-Medium.woff') format('woff'),
  url('/fonts/Exo2/Exo2-Medium.ttf') format('truetype'),
  url('/fonts/Exo2/Exo2-Medium.svg#Exo2-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
