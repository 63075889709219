
.icon {
	display: inline-block;
	fill: #a6b2c4;
	&.selected {
		fill:#002257;
	}
	&.hover {
		fill:#0057dd;
	}
}

.close {
	@if (18px <= 16px) and (18px <= 16px) {
		@if (18px <= 8px) and (18px <= 8px) {
			@if 18px%2 > 0{
				padding-left:(8px-18px)/2 - 0.5px;
				padding-right:(8px-18px)/2 + 0.5px;
			}
			@else{
				padding-left:(8px-18px)/2;
				padding-right:(8px-18px)/2;
			}
			@if 18px%2 > 0{
				padding-top:(8px-18px)/2 - 0.5px;
				padding-bottom:(8px-18px)/2 + 0.5px;
			}
			@else{
				padding-top:(8px-18px)/2;
				padding-bottom:(8px-18px)/2;
			}
			width: 8px;
			height: 8px;
		}
		@else {
			@if 18px%2 > 0{
				padding-left:(16px-18px)/2 - 0.5px;
				padding-right:(16px-18px)/2 + 0.5px;
			}
			@else{
				padding-left:(16px-18px)/2;
				padding-right:(16px-18px)/2;
			}
				@if 18px%2 > 0{
				padding-top:(16px-18px)/2 - 0.5px;
				padding-bottom:(16px-18px)/2 + 0.5px;
			}
			@else{
				padding-top:(16px-18px)/2;
				padding-bottom:(16px-18px)/2;
			}
			width: 16px;
			height: 16px;
		}
	}
	@else{
		@if (18px <= 24px) and (18px <= 24px) {
			@if 18px%2 > 0{
				padding-left:(24px-18px)/2 - 0.5px;
				padding-right:(24px-18px)/2 + 0.5px;
			}
			@else{
				padding-left:(24px-18px)/2;
				padding-right:(24px-18px)/2;
			}
			@if 18px%2 > 0{
				padding-top:(24px-18px)/2 - 0.5px;
				padding-bottom:(24px-18px)/2 + 0.5px;
			}
			@else{
				padding-top:(24px-18px)/2;
				padding-bottom:(24px-18px)/2;
			}
			width: 24px;
			height: 24px;
		}
		@else{
			width: 18px;
			height: 18px;
		}
	}
}
.icons--arrow-down {
	@if (10px <= 16px) and (7px <= 16px) {
		@if (10px <= 8px) and (7px <= 8px) {
			@if 10px%2 > 0{
				padding-left:(8px-10px)/2 - 0.5px;
				padding-right:(8px-10px)/2 + 0.5px;
			}
			@else{
				padding-left:(8px-10px)/2;
				padding-right:(8px-10px)/2;
			}
			@if 7px%2 > 0{
				padding-top:(8px-7px)/2 - 0.5px;
				padding-bottom:(8px-7px)/2 + 0.5px;
			}
			@else{
				padding-top:(8px-7px)/2;
				padding-bottom:(8px-7px)/2;
			}
			width: 8px;
			height: 8px;
		}
		@else {
			@if 10px%2 > 0{
				padding-left:(16px-10px)/2 - 0.5px;
				padding-right:(16px-10px)/2 + 0.5px;
			}
			@else{
				padding-left:(16px-10px)/2;
				padding-right:(16px-10px)/2;
			}
				@if 7px%2 > 0{
				padding-top:(16px-7px)/2 - 0.5px;
				padding-bottom:(16px-7px)/2 + 0.5px;
			}
			@else{
				padding-top:(16px-7px)/2;
				padding-bottom:(16px-7px)/2;
			}
			width: 16px;
			height: 16px;
		}
	}
	@else{
		@if (10px <= 24px) and (7px <= 24px) {
			@if 10px%2 > 0{
				padding-left:(24px-10px)/2 - 0.5px;
				padding-right:(24px-10px)/2 + 0.5px;
			}
			@else{
				padding-left:(24px-10px)/2;
				padding-right:(24px-10px)/2;
			}
			@if 7px%2 > 0{
				padding-top:(24px-7px)/2 - 0.5px;
				padding-bottom:(24px-7px)/2 + 0.5px;
			}
			@else{
				padding-top:(24px-7px)/2;
				padding-bottom:(24px-7px)/2;
			}
			width: 24px;
			height: 24px;
		}
		@else{
			width: 10px;
			height: 7px;
		}
	}
}
.icons--close {
	@if (18px <= 16px) and (18px <= 16px) {
		@if (18px <= 8px) and (18px <= 8px) {
			@if 18px%2 > 0{
				padding-left:(8px-18px)/2 - 0.5px;
				padding-right:(8px-18px)/2 + 0.5px;
			}
			@else{
				padding-left:(8px-18px)/2;
				padding-right:(8px-18px)/2;
			}
			@if 18px%2 > 0{
				padding-top:(8px-18px)/2 - 0.5px;
				padding-bottom:(8px-18px)/2 + 0.5px;
			}
			@else{
				padding-top:(8px-18px)/2;
				padding-bottom:(8px-18px)/2;
			}
			width: 8px;
			height: 8px;
		}
		@else {
			@if 18px%2 > 0{
				padding-left:(16px-18px)/2 - 0.5px;
				padding-right:(16px-18px)/2 + 0.5px;
			}
			@else{
				padding-left:(16px-18px)/2;
				padding-right:(16px-18px)/2;
			}
				@if 18px%2 > 0{
				padding-top:(16px-18px)/2 - 0.5px;
				padding-bottom:(16px-18px)/2 + 0.5px;
			}
			@else{
				padding-top:(16px-18px)/2;
				padding-bottom:(16px-18px)/2;
			}
			width: 16px;
			height: 16px;
		}
	}
	@else{
		@if (18px <= 24px) and (18px <= 24px) {
			@if 18px%2 > 0{
				padding-left:(24px-18px)/2 - 0.5px;
				padding-right:(24px-18px)/2 + 0.5px;
			}
			@else{
				padding-left:(24px-18px)/2;
				padding-right:(24px-18px)/2;
			}
			@if 18px%2 > 0{
				padding-top:(24px-18px)/2 - 0.5px;
				padding-bottom:(24px-18px)/2 + 0.5px;
			}
			@else{
				padding-top:(24px-18px)/2;
				padding-bottom:(24px-18px)/2;
			}
			width: 24px;
			height: 24px;
		}
		@else{
			width: 18px;
			height: 18px;
		}
	}
}
.icons--placeholder {
	@if (58px <= 16px) and (70px <= 16px) {
		@if (58px <= 8px) and (70px <= 8px) {
			@if 58px%2 > 0{
				padding-left:(8px-58px)/2 - 0.5px;
				padding-right:(8px-58px)/2 + 0.5px;
			}
			@else{
				padding-left:(8px-58px)/2;
				padding-right:(8px-58px)/2;
			}
			@if 70px%2 > 0{
				padding-top:(8px-70px)/2 - 0.5px;
				padding-bottom:(8px-70px)/2 + 0.5px;
			}
			@else{
				padding-top:(8px-70px)/2;
				padding-bottom:(8px-70px)/2;
			}
			width: 8px;
			height: 8px;
		}
		@else {
			@if 58px%2 > 0{
				padding-left:(16px-58px)/2 - 0.5px;
				padding-right:(16px-58px)/2 + 0.5px;
			}
			@else{
				padding-left:(16px-58px)/2;
				padding-right:(16px-58px)/2;
			}
				@if 70px%2 > 0{
				padding-top:(16px-70px)/2 - 0.5px;
				padding-bottom:(16px-70px)/2 + 0.5px;
			}
			@else{
				padding-top:(16px-70px)/2;
				padding-bottom:(16px-70px)/2;
			}
			width: 16px;
			height: 16px;
		}
	}
	@else{
		@if (58px <= 24px) and (70px <= 24px) {
			@if 58px%2 > 0{
				padding-left:(24px-58px)/2 - 0.5px;
				padding-right:(24px-58px)/2 + 0.5px;
			}
			@else{
				padding-left:(24px-58px)/2;
				padding-right:(24px-58px)/2;
			}
			@if 70px%2 > 0{
				padding-top:(24px-70px)/2 - 0.5px;
				padding-bottom:(24px-70px)/2 + 0.5px;
			}
			@else{
				padding-top:(24px-70px)/2;
				padding-bottom:(24px-70px)/2;
			}
			width: 24px;
			height: 24px;
		}
		@else{
			width: 58px;
			height: 70px;
		}
	}
}
.icons--search {
	@if (24px <= 16px) and (24px <= 16px) {
		@if (24px <= 8px) and (24px <= 8px) {
			@if 24px%2 > 0{
				padding-left:(8px-24px)/2 - 0.5px;
				padding-right:(8px-24px)/2 + 0.5px;
			}
			@else{
				padding-left:(8px-24px)/2;
				padding-right:(8px-24px)/2;
			}
			@if 24px%2 > 0{
				padding-top:(8px-24px)/2 - 0.5px;
				padding-bottom:(8px-24px)/2 + 0.5px;
			}
			@else{
				padding-top:(8px-24px)/2;
				padding-bottom:(8px-24px)/2;
			}
			width: 8px;
			height: 8px;
		}
		@else {
			@if 24px%2 > 0{
				padding-left:(16px-24px)/2 - 0.5px;
				padding-right:(16px-24px)/2 + 0.5px;
			}
			@else{
				padding-left:(16px-24px)/2;
				padding-right:(16px-24px)/2;
			}
				@if 24px%2 > 0{
				padding-top:(16px-24px)/2 - 0.5px;
				padding-bottom:(16px-24px)/2 + 0.5px;
			}
			@else{
				padding-top:(16px-24px)/2;
				padding-bottom:(16px-24px)/2;
			}
			width: 16px;
			height: 16px;
		}
	}
	@else{
		@if (24px <= 24px) and (24px <= 24px) {
			@if 24px%2 > 0{
				padding-left:(24px-24px)/2 - 0.5px;
				padding-right:(24px-24px)/2 + 0.5px;
			}
			@else{
				padding-left:(24px-24px)/2;
				padding-right:(24px-24px)/2;
			}
			@if 24px%2 > 0{
				padding-top:(24px-24px)/2 - 0.5px;
				padding-bottom:(24px-24px)/2 + 0.5px;
			}
			@else{
				padding-top:(24px-24px)/2;
				padding-bottom:(24px-24px)/2;
			}
			width: 24px;
			height: 24px;
		}
		@else{
			width: 24px;
			height: 24px;
		}
	}
}
